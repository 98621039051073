@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
body {
  text-align: left;
  font-size: 14;
  font-family: sans-serif, 'Roboto', 'Helvetica',
    'Arial' /* 'Courier New', Courier, monospace */;
  background-color: '#000';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
/* body {
  font-size: 10;
  background: hsla(253, 65%, 58%, 0.164);
  background-image: url('../public/Images/homepage.png');
  background-size: cover;
  background-repeat: 'no-repeat';
   background: rgb(109, 79, 218);
  background: linear-gradient(
    135deg,
    rgb(126, 106, 214) 0%,
    #2de5fd 100%
  ); 
  font-family: 'work sans';
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
  .noPrint {
    display: none;
  }
}
@media (max-width: 600px) {
  .hideInMobile {
    display: none;
  }
}
@media (min-width: 600px) {
  .hideInFull {
    display: none;
  }
}
.Order-Header {
  background-color: #282c34;
  color: white;
}
.App-header {
  background-color: #0971f1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
ul li {
  list-style-type: none;
}

/* for login page  */
#loginform {
  max-width: 500px;
  min-width: 300px;
  max-height: 700px;
  width: 30%;
  height: 60%;
  margin: 5px auto;
  background-color: #ffffff;
  border-radius: 25px;
}

.row {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  max-width: 100%;
}

.row input {
  width: 80%;
  box-sizing: border-box;
  border: none;
  font-size: 1.3rem;
  padding-left: 1.5rem;
  padding-bottom: 1rem;
  box-shadow: inset 0px -3px 0px 0px rgba(187, 187, 187, 0.2);
  transition: box-shadow 0.2s ease-in;
}

.row input:focus {
  box-shadow: inset 0px -3px 0px 0px rgba(34, 193, 195, 0.7);
  outline: none;
}

.row input::-webkit-input-placeholder {
  opacity: 1;
  transition: opacity 0.25s ease-out;
}

.row input:hover::-webkit-input-placeholder,
.row input:focus::-webkit-input-placeholder {
  opacity: 0;
}

.row label {
  align-self: start;
  padding-left: 4.5rem;
  padding-bottom: 0.5rem;
  color: rgba(187, 187, 187, 0.9);
  font-size: large;
  font-weight: bold;
}

.row button {
  border-radius: 25px;
  width: 80%;
  height: 40px;
  font-size: 1.3rem;
  color: white;
  font-weight: 700;
  background: rgb(34, 193, 195);
  background: linear-gradient(
    90deg,
    rgba(34, 193, 195, 1) 0%,
    rgba(253, 187, 45, 1) 100%
  );
  border: 0px;
  cursor: pointer;
  transition: opacity 0.25s ease-out;
}

.row button:hover {
  opacity: 0.8;
}

#button {
  margin-bottom: 3rem;
}
ex .errmsg {
  color: red;
}

.But:hover {
  text-decoration: underline;
  background-color: red;
}
Button {
  border-color: red;
}
